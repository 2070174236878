<template>
  <div
    style="
      width: 1200px;
      min-height: calc(100vh - 100px);
      background: #fff;
      margin: 20px auto;
    "
  >
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="地址管理" name="first">
        <el-form
          :model="basicForm"
          label-width="150px"
          style="width: 1000px; margin: 30px auto"
        >
          <h4 style="margin-top: 20px">web地址：</h4>
          <div class="clearfix" style="margin-top: 20px">
            <el-form-item label="内部地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[0][1]"></el-input>
            </el-form-item>
            <el-form-item label="外网地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[0][2]"></el-input>
            </el-form-item>
            <el-form-item label="端口" style="width: 33%; float: left">
              <el-input
                type="number"
                v-model="basicForm[0][3]"
                controls-position="right"
              />
            </el-form-item>
            <el-form-item label="AppKey" style="width: 33%; float: left">
              <el-input v-model="basicForm[0][4]" controls-position="right" />
            </el-form-item>
            <el-form-item label="AccessID" style="width: 33%; float: left">
              <el-input v-model="basicForm[0][5]" controls-position="right" />
            </el-form-item>
            <el-form-item label="AccessSecret" style="width: 33%; float: left">
              <el-input v-model="basicForm[0][6]" controls-position="right" />
            </el-form-item>
          </div>
          <h4 style="margin-top: 20px">文件服务器:</h4>
          <div class="clearfix" style="margin-top: 20px">
            <el-form-item label="内部地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[1][1]"></el-input>
            </el-form-item>
            <el-form-item label="外网地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[1][2]"></el-input>
            </el-form-item>
            <el-form-item label="端口" style="width: 33%; float: left">
              <el-input
                type="number"
                v-model="basicForm[1][3]"
                controls-position="right"
              />
            </el-form-item>
            <el-form-item label="用户名" style="width: 33%; float: left">
              <el-input v-model="basicForm[1][4]" controls-position="right" />
            </el-form-item>
            <el-form-item label="密码" style="width: 33%; float: left">
              <el-input v-model="basicForm[1][5]" controls-position="right" />
            </el-form-item>
            <el-form-item label="路径" style="width: 33%; float: left">
              <el-input v-model="basicForm[1][6]" controls-position="right" />
            </el-form-item>
          </div>
          <h4 style="margin-top: 20px">媒体服务器：</h4>
          <div class="clearfix" style="margin-top: 20px">
            <el-form-item label="内部地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[2][1]"></el-input>
            </el-form-item>
            <el-form-item label="外网地址" style="width: 33%; float: left">
              <el-input v-model="basicForm[2][2]"></el-input>
            </el-form-item>
            <el-form-item label="端口" style="width: 33%; float: left">
              <el-input
                type="number"
                v-model="basicForm[2][3]"
                controls-position="right"
              />
            </el-form-item>
          </div>
        </el-form>
        <div style="margin: 50px auto; display: block; width: 200px">
          <el-button style="width: 90px" @click="basicFormreset" type="">重置</el-button>
          <el-button style="width: 90px" type="primary" @click="submitData"
            >提交</el-button
          >
        </div>
      </el-tab-pane>

      <el-tab-pane label="服务管理" name="second">
        <el-form :model="basicForm2" style="margin: 5%">
          <el-form-item label="系统日期">
            <el-input style="width: 500px" v-model="basicForm2.systemDate"></el-input>
          </el-form-item>

          <el-form-item label="系统时间">
            <el-input style="width: 500px" v-model="basicForm2.systemTime"></el-input>
          </el-form-item>
          <el-form-item label="替换LOGO">
            <input
              type="file"
              ref="logoInt"
              @change="updataFile($event)"
              accept="image/*"
            />
          </el-form-item>

          <el-form-item label="固件升级">
            <el-button v-on:click="dialogVisible = true" type="primary">升级</el-button>

            <div v-if="updateDom" class="update-jindu">
              <div :style="{ width: `${percent}%` }"></div>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="设置微信支付账户" name="third">
        <div style="width: 800px; margin: 30px auto; display: block">
          <el-form label-width="100px">
            <el-form-item label="公众号">
              <el-input v-model="wchart[0]" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="商户号">
              <el-input v-model="wchart[1]" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="商户支付密钥">
              <el-input v-model="wchart[2]" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="开发者密钥">
              <el-input v-model="wchart[3]" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="商户私钥">
              <el-input v-model="wchart[4]" placeholder=""></el-input>
            </el-form-item>
            <div style="width: 100%; text-align: center; margin-top: 60px">
              <el-button style="width: 100px" type="primary" @click="wxSubmit"
                >确 定</el-button
              >
            </div>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="固件升级"
      v-model="dialogVisible"
      width="800px"
      :before-close="handleClose"
    >
      <input type="file" @change="updataFileGu($event)" accept="bin/*" />
      <chooseEquipment style="margin-top: 30px" ref="equipment"></chooseEquipment>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="guSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import chooseEquipment from "../components/chooseEquipment.vue";
import { ElMessage, ElLoading } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import {
  upgradeProgress,
  networkSet,
  networkGets,
  uploadFileLogo,
  uploadFileGu,
  upgradeFirmware,
  wxpayGet,
  wxpaySet,
} from "../utils/api";
export default {
  components: { Plus, chooseEquipment },
  props: {},
  data() {
    return {
      updateDom: false,
      dialogVisible: false,
      imageUrl: [],
      basicForm: [
        [1, "", "", ""],
        [2, "", "", ""],
        [3, "", "", "", "", "", ""],
      ],
      activeName: "first",
      basicForm2: {},
      gufileName: "",
      percent: 0,
      interval2: null,
      wchart: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    wxSubmit() {
      wxpaySet({ params: this.wchart }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "设置成功！",
            type: "success",
            customClass: "mzindex",
          });
        }
      });
    },
    guSubmit() {
      let limits = this.$refs.equipment.checkDevice;
      // if (this.$refs.equipment.devicelist.length > 0) {
      //   limits = this.$refs.equipment.devicelist
      //     .filter((f) => {
      //       return f[6] === true;
      //     })
      //     .map((m) => {
      //       return m[1];
      //     });
      // }
      upgradeFirmware({
        params: [this.gufileName, limits],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "升级成功！",
            customClass: "mzindex",
          });
          this.dialogVisible = false;
          this.updateDom = true;
          if (this.interval2 === null) {
            this.interval2 = setInterval(function () {
              upgradeProgress({ params: [] }).then((res) => {
                this.percent = res.result[0] * 100;
                if (res.result[0] * 100 == 100) {
                  this.updateDom = false;
                  clearInterval(this.interval2);
                }
              });
            }, 3000);
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    submitData() {
      this.basicForm[2][4] = "";
      this.basicForm[2][5] = "";
      this.basicForm[2][6] = "";
      networkSet({
        params: this.basicForm,
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
        }
      });
    },
    basicFormreset() {
      this.basicForm = [
        [1, "", "", ""],
        [2, "", "", ""],
        [3, "", "", ""],
      ];
    },
    updataFileGu(event) {
      let loading = ElLoading.service({
        lock: true,
        text: "正在上传文件！",
        background: "rgba(255,255,255, 0.7)",
        customClass: "mzindex",
      });
      //上传音乐
      let formFile = new FormData();
      let fileData = event.target.files[0];
      formFile.append("params", fileData);
      this.gufileName = fileData.name;
      uploadFileGu(formFile)
        .then((res) => {
          if (!res.error) {
            this.updatafileData = event.target.files[0];
            ElMessage({
              message: "上传成功！",
              type: "success",
              customClass: "mzindex",
            });
            // this.$refs.file1.reset();
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    updataFile(event) {
      let loading = ElLoading.service({
        lock: true,
        text: "正在上传文件！",
        background: "rgba(255,255,255, 0.7)",
        customClass: "mzindex",
      });
      //上传音乐
      let formFile = new FormData();
      let fileData = event.target.files[0];
      formFile.append("params", fileData);

      uploadFileLogo(formFile)
        .then((res) => {
          if (!res.error) {
            this.updatafileData = event.target.files[0];
            ElMessage({
              message: "上传成功！",
              type: "success",
              customClass: "mzindex",
            });
            // this.$refs.file1.reset();
          }
        })
        .finally(() => {
          loading.close();
        });
    },
  },
  created() {
    networkGets({ params: [] }).then((res) => {
      if (!res.error) {
        this.basicForm[0] = res.result[0].slice(0, 7);
        this.basicForm[1] = res.result[1].slice(0, 7);
        this.basicForm[2] = res.result[2].slice(0, 7);
      }
    });
    wxpayGet({ params: [] }).then((res) => {
      this.wchart = res.data;
    });
  },
  mounted() {},
  beforeUnmount() {
    this.updateDom = false;
    clearInterval(this.interval2);
    this.interval2 = null;
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.update-jindu {
  width: 60px;
  height: 7px;
  border-radius: 7px;
  background: #ddd;
  margin-left: 10px;
}

.update-jindu > div {
  height: 7px;
  background: #409eff;
  border-radius: 7px;
}
</style>
